.toolbar {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  background: #3498db;
  height: 56px;
  z-index: 200;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
}

.toolbar_navigation {
  top: 0;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.toolbar_logo {
  margin-left: 1rem;
}

.toolbar_logo a {
  color: white;
  text-decoration: none;
  font-size: 1.3rem;
}

.spacer {
  flex: 1;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation-items a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: #1d5e85;
}

@media (max-width: 768px) {
  .toolbar_navigation-items {
    display: none;
  }
}

/* @media (min-width: 769px) {
    .toolbar_toggle-button {
        display:none;
    }
} */
