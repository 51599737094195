.side-drawer {
  height: 100%;
  background: linear-gradient(120deg, #3498db, #8e44ad);
  /* box-shadow: 1px 0px 7px rgba(0, 0, 0 , 0.5); */
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  overflow: auto; /* showing scrollbars */
}

.side-drawer.open {
  transform: translateX(0);
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
}

.side-drawer ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.side-drawer li {
  margin: 0.5rem 0;
}
.side-drawer a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.side-drawer li a:hover,
.side-drawer li a:active {
  color: #1d5e85;
}

.side-drawer a:active {
  color: #1d5e85;
}

.side-drawer-update {
  bottom: 0;
  margin-top: 5px;
}

/* @media (min-width: 769px) {
    .side-drawer {
        display: none;
    }
} */
