.color {
    color: #3498db;
}

.color-button{
    background: #3498db ;
}

.color-border{
    border: solid;
    border-color: #3498db;
}

.input-type{
    border-top: 0;
    border-right: 0;
    border-left: 0;
    
}

.input-type:focus{
    outline:none;
    border-color: #3498db;
    
}

@media (max-width:768px){
    .color-border{
        border: none;
    }
}