.mobilebar{
    bottom: 0px;
    left:0 ;
    position:fixed;
    width: 100%;
    background: #3498db;
    height: 56px;
    box-shadow: 0px 0px 7px rgba(0,0,0,0.5);
}

.mobilebar ul{
    list-style: none;
    display: inline-block;
    padding:7px 0px 0 0px;
    width:100%;
}

.mobilebar li{   
    width: 20%;
    display: inline-block;
    height: 100%;
    text-align: center;
}

.mobilebar li a{
    display: inline-block;
    color: white;
    text-decoration: none;
    font-size: 0.8rem;
}
.mobilebar a.active{
    color: #1d5e85;
}

.mobilebar li a:hover,
.mobilebar li a:active {
    color: #1d5e85;
}

/* @media (min-width: 769px){
    .mobilebar {
        display: none;
    }
} */

