.imageAnchor:focus img {
  border: 2px solid blue;
}

:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.divVariante .lblVariante {
  font-size: 18px;
  font-weight: 500;
  color: #666;
  display: block;
}
.divVariante .variante-radio {
  display: inline-block;
}
.variante-radio input[type='radio'] {
  display: none;
}
.variante-radio input[type='radio'] + label {
  background-position: 50%;
  height: 50px;
  width: 50px;
  display: inline-block;
  position: relative;
  border: 4px solid #fff;
  border-radius: 5%;
  -webkit-filter: grayscale(80%);
  filter: grayscale(80%);
}
.variante-radio input[type='radio'] + label:hover {
  cursor: pointer;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.variante-radio input[type='radio']:checked + label {
  border-color: #3498db;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.variante-radio input[type='radio'] + label i {
  font-size: 12px;
  position: absolute;
  left: 32px;
  bottom: -8px;
  color: #fff;
  background: linear-gradient(120deg, #3498db, #8e44ad);
  border-radius: 100%;
  border: 2px solid #3498db;
  padding: 2px;
  display: none;
}
.variante-radio input[type='radio']:checked + label i {
  display: inline-block;
}

.fa-check {
  font-family: 'FontAwesome';
}
.fa-check:before {
  content: '\f00c';
}

.text-button button {
  font-size: 12px;
}

.text-button button:hover {
  cursor: pointer;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.text-button button:active {
  font-size: 15px !important;
  background: #eaeded !important;
  padding: 2px !important;
  text-decoration-line: underline !important;
}
