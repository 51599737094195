span:active {
  background-color: yellow;
}

.buttonGod {
  /* width: 50px;
  padding: 3px;
  background-color:#3498db;
  border-color:#3498db ;
  color: white;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem; */

  width: 50px;
  display: inline-block;
  font-weight: 400;
  color: white;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color:#3498db;
  border: 1px solid #3498db;
  padding: 3px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  /* transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
}
.buttonGod:hover {
  color: white;
  text-decoration: none;
  background-color: #CD5C5C;
  border-color: #CD5C5C ;
}

.buttonGod:focus {
  outline: 0;
  background-color: #CD5C5C;
  border-color: #CD5C5C ;
  box-shadow: 0 0 0 0.2rem rgba(230, 173, 173);
}

.hidden{
  visibility: visible;
}

@media (max-width: 767px) {
    .hidden {
      visibility: hidden;
      display: none;
    }
}

@media (max-width: 375px) {
  .textSize{
    font-size: 12px;
  }
}